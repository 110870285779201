import './App.css';
import Privacy from './pages/Privacy';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  return (
    <BrowserRouter>
    <Routes>
          <Route index element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
