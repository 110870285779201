import React from "react";
import '../App.css';

export default function TermsAndConditions() {

  return (   
    <div> 
    <div className='Body'>
      <h1>Terms of use </h1>
      <p>Access to and use of this <b>KS Merchant Pty Ltd</b> Web site is provided subject to  these conditions.</p>  
      <p>Use of this Site constitutes acceptance of these conditions in full.  </p>
      <p>The information relating to <b>KS Merchant Pty Ltd</b> and its subsidiaries or affiliated  companies and its franchisees (collectively "<b>KS Merchant Pty Ltd</b>") and HOME  FREIGHT 's products and services on this <b>KS Merchant Pty Ltd</b> Site is provided  subject to the following conditions:  </p>
      <h2>Copyright </h2>
      The copyright in this publication is owned by <b>KS Merchant Pty Ltd</b>.  
      No person may reproduce any portion of the material in these web pages without  the prior written consent of <b>KS Merchant Pty Ltd</b>.  
      <b>KS Merchant Pty Ltd</b> Trademarks  
      <b>KS Merchant Pty Ltd</b> ® (Phrase Trademark) and <b>KS Merchant Pty Ltd</b> (Logo  Trademark) are trademarks of <b>KS Merchant Pty Ltd</b> or its subsidiaries. No  license to use any of these trademarks is given or implied. These trademarks may  not be copied, downloaded, reproduced, used, modified or distributed in any way  without prior written permission.  

      <h2>Viruses  </h2>
      Whilst <b>KS Merchant Pty Ltd</b> makes all reasonable attempts to exclude viruses from  this <b>KS Merchant Pty Ltd</b> Site, it cannot ensure such exclusion and no liability is  accepted for viruses. Thus, you are recommended to take all appropriate  safeguards before downloading information from this <b>KS Merchant Pty Ltd</b> Site.  

      <h2>Disclaimer of Warranties  </h2>
      <p>Information on this Internet site is provided as is. <b>KS Merchant Pty Ltd</b>, to the  fullest extent permitted by law, makes no warranties or representations about the  accuracy or completeness of this <b>KS Merchant Pty Ltd</b> Site content. HOME  FREIGHT nor any of its contractors or employees shall be liable for any direct,  incidental, consequential, indirect or punitive damages arising out of access to or  use of any content of this <b>KS Merchant Pty Ltd</b> Site.  
      In no event shall <b>KS Merchant Pty Ltd</b> or any of its contractors or employees be  liable for any damages whatsoever resulting from loss of use, data or profits 
      arising out of or in connection with the use or performance of software,  documents, provision of or failure to provide services, or information available  from this <b>KS Merchant Pty Ltd</b> Site.  
      If you are dealing as a consumer, these provisions do not affect your statutory  rights that cannot be waivered, if any. </p> 

      <h2>Products and Services  </h2>
      <p>Unless otherwise agreed in writing, the transportation products and services  mentioned in these web pages are subject to <b>KS Merchant Pty Ltd</b>'s Terms and  Conditions of carriage.  
      This <b>KS Merchant Pty Ltd</b> Site is provided solely for the use of current and  potential <b>KS Merchant Pty Ltd</b> customers to interact with <b>KS Merchant Pty Ltd</b> and  may not be used by any other person or entity, or for any other purpose. Use of  this <b>KS Merchant Pty Ltd</b> Site to provide information to or prepare shipments by or  for the benefit of third party shippers is expressly prohibited.  
      </p>
      <h2>Use of Online Booking Features  </h2>
      <p>
      By transmitting or posting any communication of material to this HOME  FREIGHT Site, you agree that <b>KS Merchant Pty Ltd</b> may use your  communication as material for any purpose, including reproduction, transmission,  publication, broadcasting and posting. With respect to your personal data,  reference is made to the Privacy Statement of <b>KS Merchant Pty Ltd</b>.  
      The sender of any communications to this <b>KS Merchant Pty Ltd</b> Site shall be  responsible for the content and information contained therein, including its  truthfulness and accuracy.  
      <b>KS Merchant Pty Ltd</b> may discontinue, suspend or modify the <b>KS Merchant Pty Ltd</b>  Site at any time without notice, and <b>KS Merchant Pty Ltd</b> may block, terminate or  suspend any user's access to the <b>KS Merchant Pty Ltd</b> Site at any time for any  reason in its sole discretion, even if access coTerms of use  
      Access to and use of this <b>KS Merchant Pty Ltd</b> Web site is provided subject to these  conditions.  
      Use of this Site constitutes acceptance of these conditions in full.  
      The information relating to <b>KS Merchant Pty Ltd</b> and its subsidiaries or affiliated  companies and its franchisees (collectively "<b>KS Merchant Pty Ltd</b>") and HOME 
      FREIGHT 's products and services on this <b>KS Merchant Pty Ltd</b> Site is provided  subject to the following conditions:  
      </p>
      <h2>Links to other Web Sites  </h2>
      <p>There are links on the <b>KS Merchant Pty Ltd</b> Site that allow you to visit the sites of  third parties. Neither these sites nor the companies to whom they belong are  controlled by <b>KS Merchant Pty Ltd</b>. <b>KS Merchant Pty Ltd</b> makes no representations  concerning the information provided or made available on such sites nor the  quality or acceptability of the products or services offered by any persons or  entities referenced in any such sites.  
      <b>KS Merchant Pty Ltd</b> has not tested and makes no representations regarding the  correctness, performance or quality of any software found at any such sites. You  should research and assess the risks which may be involved in accessing and  using any software on the Internet before using it.  
      </p>
      <h2>Revision of Terms of Use </h2>
      <p>
      <b>KS Merchant Pty Ltd</b> may revise these terms of use. You agree to be bound by any  such revisions and should therefore visit this page every time you visit the  <b>KS Merchant Pty Ltd</b> Site to determine the current terms to which you are bound.  
      ntinues to be allowed to others.  
      </p>
      <h2>Retail Service Office Terms & Conditions  </h2>
      <p>
      The following Terms and Conditions are applicable to the carriage of Goods  booked through <b>KS Merchant Pty Ltd</b>’s Retail Service Office in Western Australia.  Contact details and locations of <b>KS Merchant Pty Ltd</b> Retail Service Office can be  found at https://www.ksmerchant.com.au/. Separate Terms and Conditions are  applicable to Goods booked through <b>KS Merchant Pty Ltd</b>’s automated Online  Self-Service system which can be viewed at https://www.ksmerchant.com.au/.  
      If there is a conflict between these Terms and Conditions and the Terms and  Conditions of any <b>KS Merchant Pty Ltd</b> Retail Service Office consignment note or  other agreement from a Retail Service Centre, then these Terms and Conditions  will apply to the extent permitted at law and under the Competition and  Consumer Act 2010.  
      </p>
      <h2>1. DEFINITION </h2>
      <p>
      In these Conditions:  
      </p>
      <p>
      a. "<b>KS Merchant Pty Ltd</b> Retail Service Office ", "we", "us" and "our" means the  entity <b>KS Merchant Pty Ltd</b>, CAN 621 406 326 trading as "HOME  FREIGHT" that is selling Services from the <b>KS Merchant Pty Ltd</b> Retail Service  Office premises as specified on the face of the consignment note, and includes  their employees, contractors, agents and independent contractors;  
      <br/>
      b. "you" and "your" means our customer (being the person or party who engages  us to provide Services);  
      <br/>
      c. "Freight plus Warranty" means an extended service option that, subject to these  Terms and Conditions, includes us providing a warranty for physical loss or  damage of the Goods whilst in our care, custody or control as set out in condition  8 below;  
      <br/>
      d. "Goods" means the items accepted by the <b>KS Merchant Pty Ltd</b> Retail Service  Office for carriage under this contract of carriage;  
      <br/>
      e. "Market value" means the price that would be negotiated in an open and  unrestricted market between a knowledgeable, willing but not anxious buyer and  a knowledgeable, willing but not anxious seller acting at arm's length;  
      <br/>
      f. "Services" means all of the operations and services provided or to be provided  by us in connection with the carriage of the Goods including without limitation,  the carriage, transport and/or incidental storage of the Goods or any operations or  service incidental to any of them;  
      <br/>
      g. "Sub-Contractor" includes any person who under a contract or arrangement  with any person (whether us or not) performs or agrees to perform the Services or  any part of the Services;  
      <br/>
      h. Words denoting the singular include the plural and vice versa; any gender  includes the other gender; and persons include corporations and bodies politic and  include their legal personal representatives and assigns.  
      </p>
      <h2>2. SERVICES & PRICING </h2>
      <p>
      2.1 <b>KS Merchant Pty Ltd</b> is offering following services through its automated  Retail Service office: 
      <ui> 
        <li>
          COURIER service though which you can send your parcel with Home  Freight Courier Services and enjoy the hassle-free delivery worldwide. 
        </li>
        <li>
          ROAD FREIGHT service through which we are able to transport all  manner of freight by road from parcels and documents, household goods, to  oversized machinery and bulk commodities.  
        </li>
        <li>
          HOT SHOT DELIVERY is for transport Services to Australia’s Mining,  and Offshore Oil, Gas, and Power industries. In HOTSHOT we provide  freight direct from pick-up point to delivery point, and we deliver right  across Australia – anywhere, anytime. When required we provide 2 driver  delivery team to ensure safe delivery of your goods.  
        </li>
      </ui>
      </p>
      <p>
      Further, methods of carriage include road, air, rail or sea by way of Services that  include (but are not limited to) postal, express courier, specialised freight, general  freight and furniture moving services. If you instruct us to use a particular method  of carriage we will give priority to the method designated but if that method  cannot conveniently be adopted by us you shall be deemed to authorise us to  carry or have the goods carried by another method or methods.  
      </p>
      <p>
      2.2 If your Goods have not been sighted by us, our price estimate is based on the  information you have communicated to us. This information includes weight,  dimensions, item value, fragility and destination. If on the receipt of Goods at our  Retail Service Office this information has changed, then the price to be charged to  you may also change. This price may be higher or lower than the original price  estimate provided to you over the phone or by email. We will obtain your  approval before proceeding with any Services that may need to be charged at a  rate higher than the original price estimate.  
      <br/>
      2.3 If we have agreed to provide Services 'to the Port' you are responsible for  collecting the Goods from the assigned agent at the destination port. Prices  quoted for 'to the Port' deliveries do not include any destination or port charges  and you will be responsible for the payment of any destination or port charges  incurred and due upon arrival of your Goods at the destination port. Destination  and port charges vary from country to country and we have no control over any  such deemed charges and we are not responsible for any payment whatsoever of  destination and port charges. You also agree that if your Goods are not cleared  and collected by you immediately from the arrival port that storage charges may  also apply and you will be responsible for the payment of such storage charges.  
      <br/>
      2.4 If it is agreed that we will provide a Drop-Off service you must drop off and  lodge your Goods at our Retail Service Office during Service Centre opening 
      hours and if it is agreed that we will provide a Hold for Collection service you  must consign your Goods for delivery to the nearest <b>KS Merchant Pty Ltd</b> Service  Office at its intended destination in order for the recipient to conveniently collect  the Goods during service centre opening hours.  
      <br/>
      2.5 Goods accepted by us for carriage are subject to the following conditions:  
      </p>
      <p>
      2.5.1 that the Goods comply with the requirements of any applicable law relating  to the nature, condition and packaging of Goods and you accept that any expenses  and charges incurred by us in complying with legal requirements will be paid by  you;  
      <br/>
      2.5.2 that if any Goods are subject to the control of the customs or any  authorities, all duty, excise duty and costs which we become liable to pay and do  pay shall be paid by you (including any storage charges and costs/expenses if  items are destroyed);  
      <br/>
      2.5.3 that the Goods are fully and adequately described in writing in the space  provided on the face of this consignment note;  
      <br/>
      2.5.4 that the person delivering any Goods to our Retail Service Office for  carriage or forwarding is authorised to sign this consignment note for you and  that you as our customer agree to indemnify us for any liability we may incur to  any other party in any way relating to the provision of the Services;  
      <br/>
      2.5.5 that you will inform us of any known registered security interests in the  Goods and acknowledge that we will have a security interest over any assets  which are the subject of the contracted services and in our possession and  acknowledge and consent to the registration and protection of that interest  pursuant to the Personal Property Securities Act 2009 (Cth) (as amended) and the  Regulations made under that Act; and you will indemnify us for any loss or  damage resulting from a breach of these provisions.  
      <br/>
      </p>
      <p>
      2.6 You authorise us (if we should think fit to do so) to arrange with a sub contractor for the carriage of any Goods the subject of this contract. Any such  arrangement shall be deemed to be ratified by you upon delivery of the said  Goods to such sub-contractor.  
      <br/>
      2.7 You shall be deemed to authorise any deviation from the usual route or  manner of carriage of Goods which may in our absolute discretion be deemed  reasonable or necessary in the circumstances. 
      <br/>
      2.8 You authorise us to contract with sea or air carriers under bills of lading or air  waybills and in doing so we will at all times act as an agent for you and you will  be bound by the terms of any such bill of lading or air waybill as if it is entered  into by you.  
      <br/>
      2.9 In circumstances where you elect for a person to be in attendance to receive  the Goods and a person is not in attendance at the address given during normal  business hours when delivery is attempted, an additional charge may be made at  ruling rates for each delivery until delivery is accomplished. If you elect to not be  in attendance when the Goods are delivered then the goods may be left without  receipt at the nominated address. If the nominated address for delivery is to  another <b>KS Merchant Pty Ltd</b> Service Centre and the Goods are not collected within  5 business days from the date of delivery then you must pay a storage fee of  AUD$2.00 plus GST per item per day for each day the Goods remain  uncollected. The Goods will not be released until the fee is paid in full.  
      <br/>
      2.10 We do not guarantee to stop any Goods once in transit if requested by you to  do so although we will use our best endeavours to do so. We will not be liable for  any claim relating to Goods that you have requested to be stopped in transit or  returned but are not stopped or are delivered to the receiver.  
      </p>
      <h2>3. DANGEROUS GOODS  </h2>
      <p>
      3.1 Except in the circumstances shown in condition 3.2 below we do not carry,  nor perform other services regarding, goods which in our opinion are dangerous  goods including, but not limited to, those specified in the International Civil  Aviation Organisation (ICAO) technical instructions, the International Air  Transport Association (IATA) dangerous goods regulations, the International  Maritime Dangerous Goods (IMDG) code, the European Agreement concerning  the international carriage of dangerous goods by Road (ADR) regulations or any  other national or international rules applicable to the transport of, or the  performance of other Services regarding, dangerous goods (collectively referred  to as 'dangerous goods'). Please refer to our website at http:// 
      <b>KS Merchant Pty Ltd</b>.com.au. Separate Terms and Conditions are applicable to goods  booked through <b>KS Merchant Pty Ltd</b>’s automated Online Self-Service system  which can be viewed at https://www.ksmerchant.com.au/ for some examples of Goods  that are classified as dangerous goods.  
      <br/>
      3.2 We may at our discretion accept some dangerous goods for carriage.  Dangerous goods will only be accepted if they comply with the applicable  regulations, codes and technical instructions (as referred to in condition 3.1) and  any further requirements as specified by us. 
      <br/>
      3.3 Whether or not you have told us that a shipment comprises dangerous goods  you agree that if we consider on reasonable grounds that the Goods may cause  injury or damage, we may at your cost do anything appropriate to minimise or  avoid such injury or damage, including disposing of or destroying such Goods.  We will not be liable to you for any loss or damage you may incur by reason of  our actions under this condition.  
      </p>
      <h2>4. AIR CARGO SECURITY REGULATIONS  </h2>
      <p>
      4.1 You must ensure and you certify by completing our consignment note or  tendering Goods to us that the Goods do not contain a prohibited article as  specified in ICAO Annex 17 or other national or international regulations that  govern aviation security. You must give us a full description of your Goods on the  consignment note and other accompanying document, and your responsibilities  and liabilities are not extinguished by providing this information. Goods carried,  or handled, by us may be subject to security screening which could include the  use of X-ray equipment and you accept that packages containing your Goods may  be opened and the contents of your packages may be examined in transit.  
      <br/>
      4.2 You declare that you have prepared the Goods for carriage, or for the  performance by us of other Services, in secure premises using reliable staff  employed by you and that the goods have been safeguarded against unauthorised  
      interference during preparation, storage and transportation immediately prior to  acceptance for carriage of the Goods by us.  
      </p>
      <h2>5. RIGHT OF INSPECTION  </h2>
      <p>
      5.1 You agree that we or any governmental authority including customs may open  and inspect your shipment at any time and take any action considered necessary  in relation to the Goods if we (or any governmental authorities) consider the need  to verify the condition, nature, ownership or destination of the Goods or if we (or  any governmental authorities) consider the shipment may contain prohibited  items or dangerous goods.  
      </p>

      <h2>6. METHOD OF PAYMENT </h2>
      <p> 
      6.1 Our charges are earned as soon as the Goods are delivered to us or collected  from you or from the address nominated by you.  
      <br/>
      6.2 Acceptable methods of payment are major credit cards and cash. Businesses  that send a minimum monthly shipment volume through the <b>KS Merchant Pty Ltd</b> 
      Retail Service Office may be provided credit privileges by paying on invoice  within fourteen (14) days of the invoice date (by company cheque or electronic  funds transfer) subject to us approving a completed credit application form  following credit investigation and verification. Failure to pay invoices when due  may result in you losing your credit privileges. If your credit privilege account is  not in good credit standing, your Goods may be delayed or returned by us until  the invoice is paid.  
      <br/>
      6.3 Notwithstanding any credit arrangements and subject only to the interest of  any registered security interest holder under the Personal Property Securities Act  2009 (Cth) (as amended) and the Regulations made under that Act of which we  have been given notice, we will have a general lien on your Goods, any  documents relating to your Goods and on any other of your Goods in our  possession for all charges and other amounts payable by you to us. For that  purpose we may sell all or any of your Goods in our possession after 14 days  notice to you and will apply the proceeds in or towards the discharge of the lien  together with all costs of detention, retention and the costs of sale without  liability to any person whatsoever and without prejudice to our right to otherwise  recover the charges and other amounts payable to us in respect of the carriage of  the Goods. We will pay any surplus from the sale to the entitled person.  
      <br/>
      6.4 Every special instruction that charges will be paid by any person other than  you will be deemed to include a stipulation that if such other person does not pay  the charges within 7 days of the date set for payment, then you will pay the  charges.  
      </p>
      <h2>7. EXTENT OF LIABILITY  </h2>
      <p>
      7.1 We are not a common carrier and will accept no liability as such. We reserve  the right to refuse the carriage or transport of articles for any individual, firm or  company and the transport of any articles at our discretion.  
      <br/>
      7.2 Subject to condition 8 below, all Services supplied by the <b>KS Merchant Pty Ltd</b>  Retail Service Office are at your risk, subject only to the application of the  Competition and Consumer Act 2010 and the Australian Consumer Law if  applicable.  
      <br/>
      7.3 Unless otherwise expressly agreed in writing or our Services are subject to the  Freight plus Warranty conditions, we will be under no liability in tort or contract  or otherwise for any loss of or damage to or failure to deliver or delay in delivery  of Goods, either in handling, packing, or in transit or in storage and whether  caused by our negligence, wrongful act or default or by any other cause 
      whatsoever. We will also be under no liability for loss, damage or injury to any  person, property or thing during the provision of the contracted services, nor for  any loss consequently or otherwise arising from any such loss, damage or injury,  whether caused by our negligence, wrongful act or default or by any other cause  whatsoever.  
      <br/>
      7.4 To the extent permitted at law and under the Competition and Consumer Act  2010, our liability arising out of any one incident whether or not there has been  any declaration of value of the goods, for breach of guarantee implied into these  
      Terms and Conditions by the Competition and Consumer Act 2010 or howsoever  arising, is limited to any of the following as determined by us: (a) the supplying  of the service again; or (b) the payment of the cost of having the services supplied  again.  
      <br/>
      7.5 We do not provide insurance coverage of any kind. Insurance will be your  sole responsibility. Insurance will not be arranged by us. We will be under no  liability whatsoever for the adequacy of the insurance or your failure to arrange  insurance.  
      <br/>
      7.6 We will be discharged of all liability unless suit is brought in the proper forum  and written notice thereof received by us within six months after delivery of the  Goods or the date when the Goods should have been delivered. In the event that  the said time period shall be found contrary to any Convention or law  compulsorily applicable the period prescribed by such convention or law shall  then apply but in that circumstance only.  
      <p>
      a. In the case of carriage by sea, the value will not be declared or inserted in the  Bill of Lading for the purpose of extending the sea carrier's liability under Article  IV Rule 5(a) of Schedule 1 of the Carriage of Goods by Sea Act 1991 except  upon the express instructions given in writing by you with the agreement of the  sea carrier.  
      <br/>
      b. In the case of carriage by air, no optional declaration of value to increase the  air carrier's liability under the Civil Aviation (Carrier's Liability) Act 1959 will be  made except upon express instructions given in writing by you and with the  agreement of the air carrier.  
      <br/>
      c. In all other cases where there is a choice of tariff rates according to the extent  of the liability assumed by the carriers, warehousemen or others no declaration of  value (where optional) will be made for the purposes of extending liability and  the Goods will be forwarded or dealt with at your risk and minimum charges 
      unless express instructions in writing to the contrary are given by you and with  agreement of the carrier, warehouseman or other person.  
      </p>
      7.7 In respect of any clause herein which excludes or in any way limits our  liability in respect of the Services relating to the Goods, the <b>KS Merchant Pty Ltd</b>  Retail Service Office in addition to acting for itself, is acting as agent and trustee  for each of its employees and agents and any other person or company with  whom we may arrange for the carriage or storage of the Goods and the employees  of such person or company so that its employees and such person or company and  his or its employees are parties to this contract so far as the exclusions or  limitations of liability are concerned and if and in so far as may be necessary to  give effect to this clause we shall hold the benefit of these conditions for our  employees and for any such person or company and his or its employees.  
      <br/>
      7.8 Notwithstanding any condition herein limiting or excluding our liability,  where the contract involves the transportation and/or storage of Goods otherwise  than for the purpose of a business, trade, profession or occupation carried on or  engaged in by the person for whom the Goods are transported, the contract shall  be subject to the guarantees implied under the Competition and Consumer Act  2010 and the Australian Consumer Law and in particular the guarantee that such  transportation and storage will be rendered with due care and skill.  
      <br/>
      7.9 In the event that, whether pursuant to any liability imposed on us or  otherwise, we make any payments to you in respect of loss or damage to or delay  in delivery of Goods (including consequential loss), you hereby assign to us all  rights which you may have under any policy of insurance or with any third party  to recover such loss and you hereby irrevocably appoint us as your Attorney with  full power in your name to claim, demand, sue for, recover any such  
      amount and you will execute all such documents and provide all such information  as may be necessary to enable us to obtain the full benefit of this clause.  
      </p>
      <h2>8. FREIGHT PLUS WARRANTY  </h2>
      <p>
        <p>
      8.1 Where the Freight plus Warranty service when offered by <b>KS Merchant Pty Ltd</b>  has been purchased by you for any Goods, conditions 7.2, 7.3 and 7.4 do not  apply to loss or damage to those Goods in our care, custody or control, but we  will not be liable for consequential loss of any kind (including loss of intrinsic  value of Goods) or for loss or damage resulting from:  
      </p>
      a. ordinary leakage, loss in weight or volume or ordinary wear and tear of the  Goods; b. faulty manufacture or inherent vice or nature of the Goods; 
      <br/>
      c. wars or strikes, actions of government or customs authorities (including  rejection or refusal of importation permission), nuclear or radioactive  contamination, or mould;  
      <br/>
      d. insufficiency or unsuitability of packing of the Goods other than by or on  behalf of <b>KS Merchant Pty Ltd</b> Retail Service Centre;  
      <br/>
      e. mechanical or electrical breakdown or malfunction where no external evidence  of damage can be shown;  
      <br/>
      f. Loss of data;  
      <br/>
      g. delayed delivery of the Goods;  
      <br/>
      h. Goods that are destroyed by foreign customs authorities due to noncompliance  with foreign laws/regulations or delay in pick up;  
      <br/>
      i. Goods that are documents and services that are 'international mail'; j. where you provide to <b>KS Merchant Pty Ltd</b> an 'authority to leave' without signing  the delivery document'; 
      <br/>
      k. where you have acknowledged satisfactory and safe delivery of the Goods by  signing the delivery document; or  
      <br/>
      l. Goods that are bullion, cash, coins, banknotes, deeds, bonds, bills of exchange,  or other documents representing money, credit cards, gift cards or the like,  jewellery, negotiable items, gold or silver articles, precious stones or articles  containing gold, silver or precious stones, livestock, bloodstock or living plants/ things.  
      <p>
      8.2 To the extent permitted at law and under the Competition and Consumer Act  2010 and the Australian Consumer Law as scheduled to that Act, the Freight plus  Warranty service does not operate to render us liable for more than the Market  value or declared value of the goods as stated on the consignment note,  whichever is lesser.  
      </p>
      <p>
      8.3 In addition to condition 8.1 and 8.2 above, you agree:  
      </p>
      a. to notify us of any loss or damage to goods upon receipt of the Goods or in the  event of non-delivery within the time set out in condition 9.3 of these conditions  and to ensure that you make an appropriate notation of the non- delivery or  damage on the delivery docket;  
      <br/>
      b. to declare the actual Market value of the Goods in section 3 of the face of the  consignment note. If the declared value of the Goods declared in section 3 of the 
      consignment note (Declared Value of Goods) is less than the actual Market value  of the corresponding Goods (Actual Value of Goods) we will not be liable for  more than the amount in the following formula: [Declared Value of Goods  divided by Actual Value of Goods] multiplied by Declared Value of Goods;  
      <br/>
      c. that for Goods that are an entire set of goods or for pairs of goods where not all  items of the set or pair are lost or damaged, liability will be limited to the  damaged or lost part of the Goods only;  
      <br/>
      d. to provide evidence of damage to the Goods and the amount of any loss or  damage, at the request of us. You must retain both the damaged Goods and the  packaging for inspection by us or our agents or nominees;  
      <br/>
      e. that <b>KS Merchant Pty Ltd</b> Retail Service Office will have sole discretion whether  to repair or replace the Goods or whether to make a cash payment for the Market  value of the damaged or undelivered Goods. Antiques, collections, collectables,  works of art, curios and items of that nature may be replaced at the discretion of  <b>KS Merchant Pty Ltd</b>.  
      </p>

      <h2>9. CLAIMS PROCEDURE  </h2>
      <p>
      9.1 Subject to any law, regulation or convention which compulsorily applies and  which we are unable to contract out of (irrespective of whether or not we have  attempted to contract out of such law, regulation or convention), your right to  claim damages against us shall be extinguished unless you comply with the  claims procedure under this condition 9.  
      </p>
      <p>
      9.2 If you believe we are liable to you, you must:  
      </p>
      a. Contact the <b>KS Merchant Pty Ltd</b> Retail Service Office whose contact details and  location are specified on the face of your consignment note within 7 days of the  date that the cause of action giving rise to the claim arose;  
      <br/>
      b. Lodge your claim form and any supporting documents to the HOME  FREIGHT Retail Service Office within 30 days of the date that the cause of  action giving rise to the claim arose;  
      <br/>
      c. Supporting documents to be lodged by you may include photographs,  independent quotes and invoices to support your valuation of the Goods and  repairers invoices. You must prove market value/loss at your own expense. 
      <p>
      9.3 We will be discharged of all liability unless notice of loss or damage is given  to us within 7 days of delivery of the Goods or of the date by which non delivered goods should have been delivered and suit is brought in the proper  forum and written notice thereof received by us within the time applicable under  condition 7.6.  
      </p>

      <h2>10. LAW & JURISDICTION  </h2>
      <p>
      10.1 The contract will be governed and construed in accordance with the laws of  the State in which the contract is made, or if outside Australia the laws of the  State in Australia in which the Goods were delivered or ought to have been  delivered, and any dispute will be resolved in the courts of that State, unless  otherwise agreed between the <b>KS Merchant Pty Ltd</b> Retail Service Office and you.  
      </p>

      <h2>11. PRIVACY  </h2>
      <p>
      11.1 The information collected in this document will be used primarily for the  purposes of conducting the Services and may be used to forward you information  regarding <b>KS Merchant Pty Ltd</b> products and services. Access to the information is  available subject to certain conditions. Please refer to our Privacy Statement at  https://www.ksmerchant.com.au/.  
      Online Self-Service Terms & Conditions  
      </p>
      <p>
      The following Terms and Conditions are applicable to the carriage of pre-packed  Goods booked through <b>KS Merchant Pty Ltd</b> Trading as “<b>KS Merchant Pty Ltd</b>”  automated Online Self-Service system. Separate Terms and Conditions are  applicable to Goods booked through “<b>KS Merchant Pty Ltd</b>” Retail Service office  in Western Australia which can be viewed on our website  
      https://www.ksmerchant.com.au/. 
      </p> 
      <h2>1. DEFINITION  </h2>
      <p>
      In these Conditions:  
      </p>
      a. “<b>KS Merchant Pty Ltd</b>”, "we", "us" and "our" means <b>KS Merchant Pty Ltd</b>  A.C.N. 621 406 326, its related bodies corporate (as this term is defined in the  Corporations Act 2001 (Cth)) and its employees, contractors, agents and  independent contractors; 
      <br/>
      b. "you" and "your" means our customer (being the person or party who engages  us to provide Services);  
      <br/>
      c. "Goods" means the items accepted by <b>KS Merchant Pty Ltd</b> for carriage under  this contract of carriage;  
      <br/>
      d. "Market value" means the price that would be negotiated in an open and  unrestricted market between a knowledgeable, willing but not anxious buyer and  a knowledgeable, willing but not anxious seller acting at arm's length;  
      <br/>
      e. "Prohibited Online Goods" means those items classified by <b>KS Merchant Pty Ltd</b>  as being incompatible for transport through our automated Online Self-Service  system and which are not accepted for carriage and must not be booked and sent  through the Online Self-Service system.  
      <br/>
      f. "Online Self-Service" means the automated online ordering and payment  system on the website: https://www.ksmerchant.com.au/ for services provided or to be  provided by us in connection with the carriage of the Goods including without  limitation, the carriage, transport and/or related storage of the Goods or any  operations or service incidental to any of them.  
      <br/>
      g. "Sub-Contractor" includes any person who under a contract or arrangement  with any person (whether us or not) performs or agrees to perform the Services or  any part of the Services;  
      <br/>
      h. "Freight plus Warranty" means an extended service option that, subject to these  Terms and Conditions, includes us providing a warranty for physical loss or  damage of the Goods whilst in our care, custody or control as set out in condition  8 below;  
      <br/>
      i. Words denoting the singular include the plural and vice versa; any gender  includes the other gender; and persons include corporations and bodies politic and  include their legal personal representatives and assigns.  

      <h2>2. SERVICES & PRICING  </h2>
      <p>
      2.1 <b>KS Merchant Pty Ltd</b> Online Self-Service is an automated ordering and  payment system available to customers 7 days a week, 24 hours a day for a  limited range of services. Collections and deliveries of Goods occur only on a  business day. You must suitably package the Goods for carriage in accordance  with this contract of carriage. <b>KS Merchant Pty Ltd</b> is offering following services  through its automated online self-service system and booking office: 
      </p>
      a. COURIER service though which you can send your parcel with Home  Freight Courier Services and enjoy the hassle-free delivery worldwide.  
      <br/>
      b. ROAD FREIGHT service through which we are able to transport all  manner of freight by road from parcels and documents, household goods, to  oversized machinery and bulk commodities.  
      <br/>
      c. HOT SHOT DELIVERY is for transport Services to Australia’s Mining, and  Offshore Oil, Gas, and Power industries. In HOTSHOT we provide freight direct  from pick-up point to delivery point, and we deliver right across Australia –  anywhere, anytime. When required we provide 2 driver delivery team to ensure  safe delivery of your goods.  
      As part of its service offering referred under Section 2 & 2.1 <b>KS Merchant Pty Ltd</b>  will not accept Prohibited Online Goods for carriage. Items are classified as  Prohibited Online Goods based on their weight, size, value, fragility and nature of  the items and you are required to check on our website that your Goods are not  Prohibited Online Goods before booking your automated Online Self-Service  order. You may arrange transport of Prohibited Online Goods with a HOME  FREIGHT Retail Service Office under separate Terms and Conditions.  
      <p>
      2.2 Methods of carriage include road & air courier services with such Services  being selected online by you as part of the automated booking system process.  We will give priority to the method of carriage designated by you but if that  method cannot conveniently be adopted by us you shall be deemed to authorise us  to carry or have the Goods carried by another method or methods.  
      </p>
      <p>
      2.3 Online Self-Service involves a courier delivery service under which we  collect your packed Goods from your premises and deliver them to the  destination address nominated by you. In some remote locations (e.g. rural  properties and addresses outside of large regional towns) delivery to the door is  not possible and will require the recipient to collect the Goods at a local depot.  
      </p>
      <p>
      2.4 The Online Self-Service involves an automated ordering system and books  the collection of your packed Goods on the business day requested by you. If this  automated collection fails you must contact the <b>KS Merchant Pty Ltd</b> Service  Centre whose details appear on your Tax Invoice, where an alternative collection  arrangement will be booked as soon as possible. 
      </p>
      <p>
      2.5 Online Self-Service does not offer flexibility for you to nominate a collection  or delivery time slot for any given business day. However, <b>KS Merchant Pty Ltd</b>  offers a Drop-Off Service and a Hold for Collection Service option for Online  Self-Service customers that allows you to:  
      </p>
      a. Drop off and lodge your packed Goods (already booked and paid through the  automated Online Self-Service) at your chosen <b>KS Merchant Pty Ltd</b> Retail  Service Office during service centre opening hours to avoid you waiting for the  driver to collect the packed Goods at your premises.  
      <p>
      2.6 The Online Self-Service automated system books and charges for the delivery  based on the consignment details you have completed online. Our charges are  based on the greater of charges based on the actual weight or volume of the  Goods. For the purpose of calculating weight we round up to the next whole  kilogram.  
      </p>
      <p>
      2.7 In addition to the delivery charge, we may charge you surcharges and fees as  published in the Surcharge Schedule List on our website. Surcharges are applied  against your credit card or PayPal account used to complete the automated Online  Self-Service booking. The following surcharges apply to the Online-Self-Service:  
      </p>
      a. Correct Weight/Dimensions Charge: applies when the weight or dimensions of  your packed Goods reweighed or remeasured and they are calculated as being  different to that which you stated during the online booking.  
      <br/>
      b. Prohibited Online Goods Return Charge: applies to any items that are classified  as a Prohibited Online Good on our website but is booked by you through the  automated Online Self-Service system. If we deem during the act of  transportation that your item is a Prohibited Online Good then such items will be  held by us for collection by you or may be returned to you.  
      <br/>
      c. Booking Alteration Charge: applies to any alteration you request to the booking  of your automated Online Self- Service, such as a change of a delivery address.  
      <br/>
      d. Cancellation Charge: applies when an Online Self-Service booking is  cancelled.  
      <br/>
      e. Futile Booking Charge: will be charged if no-one is available to despatch the  Goods on your nominated pickup date; if the driver refuses pickup because the  required <b>KS Merchant Pty Ltd</b> Online Self-Service labels are not attached to your  packed Goods; if the driver refuses pickup because the Goods are not packaged 
      correctly for transport; if you will not release the Goods to the driver on your  nominated pickup date.  
      <br/>
      f. Waiting Despatch Charge: applicable in respect of any delays in excess of 10  minutes in loading your Goods other than from the default of us. Such  permissible delay period commences from the driver reporting for loading the  Goods.  
      <br/>
      g. Re-delivery charge: will apply where an initial attempt to deliver a  consignment fails and is returned to our depot for further processing requiring a  subsequent delivery or customer pick-up from a Service Centre, depot or Post  Office on another Working Day.  
      <br/>
      h. Return Charge: It is the responsibility of the customer when using the Online  Self-Service to track the delivery of the Goods through our website and ensure  there are no delivery issues. If delivery of your Goods has been attempted to the  nominated address, but delivery cannot be completed to the recipient, then your  Goods may be returned to you and you will be charged for the return delivery.  
      <br/>
      i. Proof of Delivery Charge: It is the responsibility of the customer when using  the Online Self-Service to track the delivery of the Goods through our website  using your assigned Online Self-Service tracking number. If you require a hard  copy Proof of Delivery a surcharge will apply.  
      <br/>
      j. Paperwork Charge: Our automated Online Self-Service system requires you to  print a bar-coded label and attach this to your packed Goods as well as printed  documentation to be handed over with your Goods. A surcharge will apply if any  paperwork is required to be produced or completed by <b>KS Merchant Pty Ltd</b>  because you have not printed the required documents.  
      <br/>
      k. Packaging Service Charge: Applicable packaging charges as quoted by HOME  FREIGHT may apply when the Goods are not packaged correctly for transport.  
      <br/>
      l. Storage Charge: applies if the Goods are not collected within 5 Working Days  from the date the <b>KS Merchant Pty Ltd</b> advises you of receipt then you must pay a  storage fee for each day the Goods remain uncollected.  
      <p>
      2.8 The Goods are accepted for carriage by <b>KS Merchant Pty Ltd</b> through the  automated Online Self-Service system on the following conditions:  
      </p>
      a. that you are an Australian resident and the Online Self-Service order is booked  using an Australian registered credit card; 
      <br/>
      b. that your Goods have been prepared and packed safely and carefully by you to  a standard as published on our website with the outer packaging and internal  cushioning sufficient to protect the Goods against shock, vibration, compression  and short drops during the handling and sortation process, transport and storage;  
      <br/>
      c. that you have printed and securely adhered the <b>KS Merchant Pty Ltd</b> automated  Online Self-Service consignment label to the packed Goods prior to collection by  the driver;  
      <br/>
      d. that for Goods booked for international delivery, you have completed the  required documentation as guided online by <b>KS Merchant Pty Ltd</b> (e.g. customs  invoice) and will provide these completed documents to the driver on collection;  
      <br/>
      e. that the items consigned are not Prohibited Online Goods and that you have  ticked a check box to state that you have read and understood what is a Prohibited  Online Good as part of this contract of carriage;  
      <br/>
      f. that the Goods comply with the requirements of any applicable law relating to  the nature, condition and packaging of goods and you accept that any expenses  and charges incurred by us in complying with legal requirements will be paid by  you;  
      <br/>
      g. that if any Goods are subject to the control of customs authorities, all duty,  excise duty, taxes and costs which <b>KS Merchant Pty Ltd</b> becomes liable to pay and  does pay shall be paid by you;  
      <br/>
      h. that you have fully and adequately described the Goods (including but not  limited to providing a full and accurate description of the Goods, weight, volume  and number of items) on the electronic booking and consignment note;  
      <br/>
      i. You are responsible for the information entered into the automated Online Self Service booking and <b>KS Merchant Pty Ltd</b> will not be held responsible for wrong  information that you provide us and any delay this may cause.  
      <br/>
      j. Guaranteed transit times are not offered by <b>KS Merchant Pty Ltd</b> automated  Online Self-Service. Any transit times quoted by us are issued as a guide only.  
      <br/>
      k. You are responsible with the Online Self-Service for ensuring that all details  are correctly completed and displayed on the correct parcel and HOME  FREIGHT will not be liable if the Goods are sent and the details were incorrect  or placed on the incorrect parcel. 
      <br/>
      l. that the person booking the Goods for carriage through the automated Online  Self-Service is the owner of the Goods or is authorised to act as the agent for the  owner on the terms of this contract of carriage and you as our customer agree to  indemnify us for any liability we may incur to any other party in any way relating  to the provision of the Services;  
      <br/>
      m. that you will inform <b>KS Merchant Pty Ltd</b> of any known registered security  interests in the Goods and acknowledge that we will have a security interest over  any assets which are the subject of the contracted services and in our possession  and acknowledge and consent to the registration and protection of that interest  pursuant to the Personal Property Securities Act 2009 (Cth) (as amended) and the  Regulations made under that Act; and you will indemnify us for any loss or  damage resulting from a breach of any of these provisions.  
      <br/>
      n. You will be liable for any damages caused in transit to other shipments or  property resulting from sending a Prohibited Online Good through the automated  Online Self-Service system.  
      <p>
      2.9 You authorise us (if we should think fit to do so) to arrange with a sub contractor for the carriage of any Goods the subject of this contract. Any such  arrangement shall be deemed to be ratified by you upon delivery of the said  Goods to such sub-contractor.  
      </p>
      <p>
      2.10 You shall be deemed to authorise any deviation from the usual route or  manner of carriage of Goods which may in our absolute discretion be deemed  reasonable or necessary in the circumstances.  
      </p>
      <p>
      2.11 You authorise us to contract with sea or air carriers under bills of lading or  air waybills and in doing so we will at all times act as an agent for you and you  will be bound by the terms of any such bill of lading or air waybill as if it is  entered into by you.  
      </p>
      <p>
      2.12 We do not guarantee to stop any Goods once in transit if requested by you to  do so although we will use our best endeavours to do so. We will not be liable for  any claim relating to Goods that you have requested to be stopped in transit or  returned but are not stopped or are delivered to the receiver.  
      </p>

      <h2>3. DANGEROUS GOODS  </h2>
      <p>
      3.1 <b>KS Merchant Pty Ltd</b> does not carry, nor perform other services regarding,  goods which are in our sole opinion are dangerous goods including, but not  limited to, those specified in the International Civil Aviation Organisation (ICAO) 
      technical instructions, the International Air Transport Association (IATA)  dangerous goods regulations, the International Maritime Dangerous Goods  (IMDG) code, the European Agreement concerning the international carriage of  dangerous goods by road (ADR) regulations or any other national or international  rules applicable to the transport of, or the performance of other services  regarding, dangerous goods (collectively referred to as 'dangerous goods'). Please  refer to our website at https://www.ksmerchant.com.au/ for some examples of Goods  that are classified as dangerous goods.  
      </p>
      <p>
      3.2 Whether you have told us that your shipment comprises dangerous goods you  agree that if we consider on reasonable grounds that the Goods may cause injury  or damage, we may at your cost do anything appropriate to minimise or avoid  such injury or damage, including disposing of or destroying such Goods. We will  not be liable to you for any loss or damage you may incur by reason of our  actions under this condition.  
      </p>
      <h2>4. AIR CARGO SECURITY REGULATIONS  </h2>
      <p>
      4.1 You must ensure and you certify by completing our consignment note or  tendering Goods to us that the Goods do not contain a prohibited article as  specified in ICAO Annex 17 or other national or international regulations that  govern aviation security. You must give us a full description of your Goods on the  consignment note and other accompanying document, and your responsibilities  and liabilities are not extinguished by providing this information. Goods carried,  or handled, by us may be subject to security screening which could include the  use of X-ray equipment and you accept that packages containing your Goods may  be opened and the contents of your packages may be examined in transit.  
      </p>
      <p>
      4.2 You declare that you have prepared the Goods for carriage, or for the  performance by us of other Services, in secure premises using reliable staff  employed by you and that the goods have been safeguarded against unauthorised  interference during preparation, storage and transportation immediately prior to  acceptance for carriage of the Goods by us.  
      </p>
      <h2>5. RIGHT OF INSPECTION  </h2>
      <p>
      5.1 You agree that we or any governmental authority including customs may open  and inspect your shipment at any time and take any action considered necessary  in relation to the Goods if we (or any governmental authorities) consider the need  to verify the condition, nature, ownership or destination of the Goods or if we (or 
      any governmental authorities) consider the shipment may contain prohibited  items or dangerous goods.  
      </p>
      <h2>6. METHOD OF PAYMENT</h2>  
      <p>
      6.1 All prices quoted by <b>KS Merchant Pty Ltd</b> automated Online Self-Service on  the website are in Australian dollars. Payment is taken by our automated Online  Self-Service system once your order has been booked with us. HOME  FREIGHT will perform payment security checks on certain transactions and  values.  
      </p>
      <p>
      6.2 In addition to the quoted delivery charges, we may charge you surcharges (as  specified in condition 2.8 of this contract of carriage) to the credit card or PayPal  account used to complete the automated Online Self-Service booking. If we are  unable to redeem the full payment from your credit card or PayPal account then  you guarantee to pay the applicable surcharges within seven (7) days notification.  Should you fail to pay any surcharges in full within 7 days of notification, you  agree to pay an accounting surcharge of 20% of the gross value of the total  overdue amount, together with the then current interest rate charged by the  Reserve Bank for loans less than $100,000.00 plus 21.5%, to be calculated on a  daily basis from the first date of the account becoming overdue until payment is  made in full, or until Judgement, as the case may be.  
      </p>
      <p>
      6.3 Subject only to the interest of any registered security interest holder of which  <b>KS Merchant Pty Ltd</b> has been given notice, we will have a general lien on your  Goods, any documents relating to your Goods and on any other of your Goods in  the possession of <b>KS Merchant Pty Ltd</b> for all charges and other amounts payable  by you to us. For that purpose we may sell all or any of your Goods in our  possession after 14 days notice to you and will apply the proceeds in or towards  the discharge of the lien together with all costs of detention, retention and the  costs of sale without liability to any person whatsoever and without prejudice to  the right of <b>KS Merchant Pty Ltd</b> to otherwise recover the charges and other  amounts payable to us in respect of the carriage of the Goods. We will pay any  surplus from the sale to the entitled person.  
      </p>
      <p>
      6.4 Every special instruction that charges will be paid by any person other than  you will be deemed to include a stipulation that if such other person does not pay  the charges within 7 days of the date set for payment, then you will pay the  charges.  
      </p>
      <h2>7. EXTENT OF OUR LIABILITY </h2>
      <p>
      7.1 We are not a common carrier and will accept no liability as such. We reserve  the right to refuse the carriage or transport of articles for any individual, firm or  company and the transport of any articles at our discretion.  
      </p>
      <p>
      7.2 Subject to condition 8 below, all Services supplied by <b>KS Merchant Pty Ltd</b> are  at your risk, subject only to the application of the Competition and Consumer Act  2010 and the Australian Consumer Law if applicable.  
      </p>
      <p>
      7.3 Unless otherwise expressly agreed in writing or our Services are subject to the  Freight plus Warranty conditions, we will be under no liability in tort or contract  or otherwise for any loss of or damage to or failure to deliver or delay in delivery  of Goods, either in handling, packing, or in transit or in storage and whether  caused by our negligence, wrongful act or default or by any other cause  whatsoever. We will also be under no liability for loss, damage or injury to any  person, property or thing during the provision of the contracted services, nor for  any loss consequently or otherwise arising from any such loss, damage or injury,  whether caused by our negligence, wrongful act or default or by any other cause  whatsoever.  
      </p>
      <p>
      7.4 To the extent permitted at law and under the Competition and Consumer Act  2010, our liability arising out of any one incident whether or not there has been  any declaration of value of the goods, for breach of guarantee implied into these  
      Terms and Conditions by the Competition and Consumer Act 2010 or howsoever  arising, is limited to any of the following as determined by us: (a) the supplying  of the service again; or (b) the payment of the cost of having the services supplied  again.  
      </p>
      <p>
      7.5 We do not provide insurance coverage of any kind. Insurance will be your  sole responsibility. Insurance will not be arranged by us. We will be under no  liability whatsoever for the adequacy of the insurance or your failure to arrange  insurance.  
      </p>
      <p>
      7.6 We will be discharged of all liability unless suit is brought in the proper forum  and written notice thereof received by us within six months after delivery of the  Goods or the date when the Goods should have been delivered. In the event that  the said time period shall be found contrary to any Convention or law  compulsorily applicable the period prescribed by such convention or law shall  then apply but in that circumstance only.  
      </p>
      a. In the case of carriage by air, no optional declaration of value to increase the air  carrier's liability under the Civil Aviation (Carrier's Liability) Act 1959 will be 
      made except upon express instructions given in writing by you and with the  agreement of the air carrier.  
      <br/>
      b. In all other cases where there is a choice of tariff rates according to the extent  of the liability assumed by the carriers, warehousemen or others no declaration of  value (where optional) will be made for the purposes of extending liability  
      and the Goods will be forwarded or dealt with at Owner's Risk or other minimum  charges unless express instructions in writing to the contrary are given by you and  with agreement of the carrier, warehouseman or other person.  
      <p>
      7.7 In respect of any clause herein which excludes or in any way limits our  liability in respect of the Services relating to the Goods, the <b>KS Merchant Pty Ltd</b>  Service Centre in addition to acting for itself, is acting as agent and trustee for  each of its employees and agents and any other person or company with whom  we may arrange for the carriage or storage of the Goods and the employees of  such person or company so that its employees and such person or company and  his or its employees are parties to this contract so far as the exclusions or  limitations of liability are concerned and if and in so far as may be necessary to  give effect to this clause we shall hold the benefit of these conditions for our  employees and for any such person or company and his or its employees.  
      </p>
      <p>
      7.8 Notwithstanding any condition herein limiting or excluding our liability,  where the contract involves the transportation and/or storage of Goods otherwise  than for the purpose of a business, trade, profession or occupation carried on or  engaged in by the person for whom the Goods are transported, the contract shall  be subject to the guarantees implied under the Competition and Consumer Act  2010 and the Australian Consumer Law and in particular the guarantee that such  transportation will be rendered with due care and skill.  
      </p>
      <p>
      7.9 In the event that, whether pursuant to any liability imposed on us or  otherwise, we make any payments to you in respect of loss or damage to or delay  in delivery of Goods (including consequential loss), you hereby assign to us all  rights which you may have under any policy of insurance or with any third party  to recover such loss and you hereby irrevocably appoints us as your Attorney with  full power in your name to claim, demand, sue for, recover any such amount and  you will execute all such documents and provide all such information as may be  necessary to enable us to obtain the full benefit of this clause.  
      </p>
      <h2>8. FREIGHT PLUS WARRANTY </h2>
      <p>
      8.1 Freight plus warranty as a service when offered by <b>KS Merchant Pty Ltd</b> and  purchased by you for any Goods, conditions 7.2, 7.3 and 7.4 do not apply to loss  or damage to those Goods in our care, custody or control, but we will not be  liable for consequential loss of any kind (including loss of intrinsic value of  Goods) or for loss or damage resulting from:  
      </p>
      a. ordinary leakage, loss in weight or volume or ordinary wear and tear of the  Goods; b. faulty manufacture or inherent vice or nature of the Goods;  
      <br/>
      c. wars or strikes, actions of government or customs authorities (including  rejection or refusal of importation permission), nuclear or radioactive  contamination, or mould;  
      <br/>
      d. insufficiency or unsuitability of packing of the Goods other than by or on  behalf of <b>KS Merchant Pty Ltd</b> Retail Service Office;  
      <br/>
      e. mechanical or electrical breakdown or malfunction where no external evidence  of damage can be shown;  
      <br/>
      f. Loss of data;  
      <br/>
      g. delayed delivery of the Goods;  
      <br/>
      h. Goods that are destroyed by foreign customs authorities due to noncompliance  with foreign laws/regulations or delay in pick up;  
      <br/>
      i. Goods that are documents and services that are 'international mail'; j. where you provide to <b>KS Merchant Pty Ltd</b> an 'authority to leave' without  signing the delivery document';  
      <br/>
      k. where you have acknowledged satisfactory and safe delivery of the Goods by  signing the delivery document; or  
      <br/>
      l. Goods that are bullion, cash, coins, banknotes, deeds, bonds, bills of exchange,  or other documents representing money, credit cards, gift cards or the like,  jewellery, negotiable items, gold or silver articles, precious stones or articles  containing gold, silver or precious stones, livestock, bloodstock or living plants/ things.  
      <p>
      8.2 To the extent permitted at law and under the Competition and Consumer Act  2010 and the Australian Consumer Law as scheduled to that Act, the Freight plus  Warranty service does not operate to render us liable for more than the Market  value or declared value of the Goods, whichever is lesser. 
      </p>
      <p>
      8.3 In addition to condition 8.1 and 8.2 above, you agree:  
      </p>
      <p>
      a. to notify us of any loss or damage to goods upon receipt of the Goods or in the  event of non-delivery within the time set out in condition 9.3 of these conditions  and to ensure that you make an appropriate notation of the non- delivery or  damage on the delivery docket;  
      <br/>
      b. to declare the actual Market value of the Goods. If the Declared Value of  Goods is less than the actual Market value of the corresponding Goods (Actual  Value of Goods) we will not be liable for more than the amount in the following  formula: [Declared Value of Goods divided by Actual Value of Goods] multiplied  by Declared Value of Goods;  
      <br/>
      c. that for Goods that are an entire set of goods or for pairs of goods where not all  items of the set or pair are lost or damaged, liability will be limited to the  damaged or lost part of the Goods only;  
      <br/>
      d. to provide evidence of damage to the Goods and the amount of any loss or  damage, at the request of us. You must retain both the damaged Goods and the  packaging for inspection by us or our agents or nominees;  
      <br/>
      e. that <b>KS Merchant Pty Ltd</b> will have sole discretion whether to repair or replace  the Goods or whether to make a cash payment for the Market value of the  damaged or undelivered Goods. Antiques, collections, collectables, works of art,  curios and items of that nature may be replaced at the discretion of HOME  FREIGHT.  
      <br/>
      </p>
      <h2>9. CLAIMS PROCEDURE  </h2>
      <p>
      9.1 Subject to any law, regulation or convention which compulsorily applies and  which we are unable to contract out of (irrespective of whether or not we have  attempted to contract out of such law, regulation or convention), your right to  claim damages against us shall be extinguished unless you comply with the  claims procedure under this condition 9.  
      </p>
      <p>
      9.2 If you believe we are liable to you, you must:  
      </p>
      a. Contact the <b>KS Merchant Pty Ltd</b> Service Centre whose details appear on your  Tax Invoice, Monday to Friday 8:30am to 5:30pm (excluding public holidays),  within 7 days of the date that the cause of action giving rise to the claim arose; 
      <br/>
      b. Complete your claim form online at https://www.ksmerchant.com.au/ or contact us on  given contact details on Tax Invoice ensuring you attach/provide the required  supporting documentation in order to process your claim;  
      <br/>
      c. Supporting documents to be lodged online by you may include photographs,  independent quotes, and invoices to support your valuation of the Goods and  repairers invoices. You must prove market value/loss at your own expense;  
      <br/>
      d. Lodge your claim form and any supporting documents online at http:// <b>KS Merchant Pty Ltd</b>.com.au or through phone or at our office within 30 days of the date  that the cause of action giving rise to the claim arose.  
      <p>
      9.3 We will be discharged of all liability unless notice of loss or damage is given  to us within 7 days of delivery of the Goods or of the date by which non delivered Goods should have been delivered and suit is brought in the proper  forum and written notice thereof received by us within the time applicable under  condition 7.11.  
      </p>
      <h2>10. LAW & JURISDICTION  </h2>
      <p>
      10.1 The contract will be governed and construed in accordance with the laws of  the State in which the contract is made, or if outside Australia the laws of the  State in Australia in which the Goods were delivered or ought to have been  delivered, and any dispute will be resolved in the courts of that State, unless  otherwise agreed between the <b>KS Merchant Pty Ltd</b> Retail Service Office and you.  
      </p>

      <h2>11. PRIVACY  </h2>
      <p>
      11.1 The information collected in this document will be used primarily for the  purposes of conducting the Services and may be used to forward you information  regarding <b>KS Merchant Pty Ltd</b> products and services. Access to the information is  available subject to certain conditions. Please refer to our Privacy Statement at  https://www.ksmerchant.com.au/.  
      </p>

      <h1>SURCHARGE SCHEDULE LIST - ONLINE SELF-SERVICE  </h1>
      <p>
      <b>KS Merchant Pty Ltd</b> has a number of surcharges relating to the Online Self Service that may be applicable in addition to the delivery charge (refer clause 2.7  of our Terms and Conditions). All fees and surcharges are quoted in Australian  dollars (AUD) and are inclusive of GST (where applicable). <b>KS Merchant Pty Ltd</b>  reserves the right to introduce new and/or vary its surcharges from time to time.  
      </p>
      <p>
      1. Correct Weight/Dimensions Charge 
      <br/>
      Applicable transport charge for the additional chargeable weight to the  destination, plus a surcharge of $10 per item.  
      </p>
      <p>
      2. Prohibited Online Goods Return Charge  
      <br/>
      Forfeit of the cost of the booking charges paid for the transport of the Prohibited  Online Goods plus any additional transport charges applicable for having the  Goods returned to you.  
      </p>
      <p>
      3. Booking Alteration Charge  
      <br/>
      $20, plus any additional transport charge that may be applicable as a result of a  change in destination.  
      </p>
      <p>
      4. Cancellation Charge  
      <br/>
      $20. A full refund may be given up until the time the consignment is despatched.  After despatch, you forfeit of the cost of the booking charges paid plus any  additional transport charges applicable for having the Goods returned to you.  
      </p>
      <p>
      5. Futile Booking Charge  
      <br/>
      $20.  
      </p>
      <p>
      6. Waiting Despatch Charge  
      <br/>
      $0.00 to 10 minutes then $100 per hour charged in 5 minute increments.  7. Re-delivery charge  
      <br/>
      $15 for each re-delivery until delivery is completed. Fee applies where an initial  attempt to deliver a consignment fails and is returned to our depot for further  processing requiring a subsequent delivery or customer pick-up from a Service  Centre, depot or Post Office.  
      </p>
      <p>
      8. Return Charge  
      <br/>
      Applicable transport charge for the return delivery.  
      </p>
      <p>
      9. Proof of Delivery Charge  
      <br/>
      $10 for each hard copy of a Proof of Delivery requested per consignment.  
      </p>
      <p>
      10. Paperwork Charge 
      <br/>
      $15.  
      </p>
      <p>
      11. Packaging Service Charge  
      <br/>
      Applicable packaging charges as quoted by <b>KS Merchant Pty Ltd</b>.  
      </p>
      <p>
      12. Storage Charge  
      <br/>
      $10.00 per item per day for each day the Goods remain uncollected. 
      </p>
      <h2>Dangerous Goods </h2>
      <p> 
      Dangerous Goods are articles or substances which are capable of posing a risk to  health, safety, property or the environment.  
      </p>
      <p>
      <b>KS Merchant Pty Ltd</b> does not carry, nor perform other services regarding, goods  which are in our sole opinion are dangerous goods including, but not limited to,  those specified in the International Air Transport Association (IATA) dangerous  goods regulations. The " No Dangerous Goods " declaration on the <b>KS Merchant Pty Ltd</b> consignment note must be completed by the customer or the  consignment will not be accepted. and our requirements. Details of our requirements are available by contacting a <b>KS Merchant Pty Ltd</b> Article Service Office. 
      </p>
      <h2>Dangerous Goods Classification International Air Transport Association (IATA)</h2>
      <p>
      Acetylene Class 2 - Flammable gas
      <br/>
      Aerosols Class 2 - Gases
      <br/>
      Alcohol  
      <br/>
      Class 3 - Flammable liquids, except alcoholic beverages complying with D2.3.1 
      <br/>
      and D10.10 Ammunition Class 1 - Explosives
      <br/>
      Anti-knock motor fuel additive Class 6 - Toxic substances
      <br/>
      Arsenic Class 6 - Toxic substances
      <br/>
      Asbestos Class 9 - Aviation regulated solid or liquid
      <br/>
      Automobile parts - some only. some Class 9 - Magnetised materials
      <br/>
      Batteries, car (or any wet cell battery) Class 8 - Corrosives
      <br/>
      Batteries, dry cell
      <br/>
      Batteries, lithium
      <br/>
      Benzene Class 3 - Flammable liquids
      <br/>
      Blasting caps Class 1 - Explosives
      <br/>
      Bleaching agents permanganate Class 5 - Oxidising agents
      <br/>
      Bromates Class 5 - Oxidising agents
      <br/>
      Butane Class 2 - Flammable gas
      <br/>
      Camping stores containing flammable gas Class 2 - Flammable gas
      <br/>
      Carbon dioxide and ethylene oxide mixtures Class 2 - Toxic gas
      <br/>
      Carbon dioxide, solid (dry ice) Class 9 - Miscellaneous
      <br/>
      Cellulose nitrate products Class 4 - Flammable solids
      <br/>
      Chemical ammunition Class 6 - Toxic substances
      <br/>
      Chemical kits Class 9 - Miscellaneous
      <br/>
      Chlorates Class 5 - Oxidising agents
      <br/>
      Chlorine Class 2 - Toxic gas
      <br/>
      Cigarette lighters containing flammable gas Class 2 - Flammable gas
      <br/>
      Cleaning compounds, flammable Class 3 - Flammable liquids
      <br/>
      Cleaning liquids corrosive Detonators  
      <br/>
      Class 8 - Corrosives Class 1 - Explosives
      <br/>
      Disinfectants, permanganate Class 5 - Oxidising agents
      <br/>
      Engine starting fluid Class 3 - Flammable liquids
      <br/>
      Engines, internal combustion Class 9 - Miscellaneous
      <br/>
      Fencing, metal some Class 9 - Magnetised materials
      <br/>
      Fibreglass repair kits Class 5 - Organic peroxides
      <br/>
      Film, cellulose nitrate Class 4 - Flammable solids
      <br/>
      Fire extinguishers containing compressed or liquefied gas Class 2 - Non 
      <br/>
      flammable, non-toxic gas
      <br/>
      Article 
      <br/>
      <h2>Dangerous Goods Classification International Air Transport Association (IATA)</h2>
      <br/>
      Firearms
      <br/>
      Fireworks Class 1 - Explosives
      <br/>
      First aid kits Class 9 - Miscellaneous
      <br/>
      Flares Class 1 - Explosives
      <br/>
      Fuses Class 1 - Explosives
      <br/>
      Gasoline Class 3 - Flammable liquids
      <br/>
      Hair colouring products some Class 5 - Organic peroxides
      <br/>
      Hair spray - aerosol container Class 2 - Gases
      <br/>
      Hair spray - pump-action container Class 3 - Flammable liquids
      <br/>
      Hydrogen cyanide Class 6 - Toxic substances
      <br/>
      Hydrogen, compressed Class 2 - Flammable gas
      <br/>
      Igniters Class 1 - Explosives
      <br/>
      Life-saving appliances Class 9 - Miscellaneous
      <br/>
      Luminous dials or luminous compounds in bulk Class 7 - Radioactive materials
      <br/>
      Magnesium powder  
      <br/>
      Class 4 - Spontaneous combustion and Class 4 - Dangerous when wet
      <br/>
      Magnets Class 9 - Magnetised materials
      <br/>
      Matches of all kinds Class 4 - Flammable solids
      <br/>
      Mercury, mercury compounds or mercury in manufactured items, such as  thermometers 
      <br/>
      Class 8 - Corrosives Mustard gas Class 6 - Toxic substances
      <br/>
      Nitrates Class 5 - Oxidising agents
      <br/>
      Nitric acid Class 8 - Corrosives
      <br/>
      Paint and paint removers, flammable Class 3 - Flammable liquids
      <br/>
      Paint or varnish removers Class 8 - Corrosives
      <br/>
      Paper caps for toy pistols Class 1 - Explosives
      <br/>
      Perchlorates Class 5 - Oxidising agents
      <br/>
      Perfumery products with flammable solvents Class 3 - Flammable liquids
      <br/>
      Class 5 - Oxidising agents
      <br/>
      Peroxides Pharmaceutical products  
      <br/>
      Class 5 - Oxidising agents 
      <br/>
      some Class 5 - Organic peroxides(other classifications may apply)
      <br/>
      Plutonium Class 7 - Radioactive materials
      <br/>
      Polymeric beads Class 9 - Miscellaneous
      <br/>
      Potassium, metallic Class 4 - Dangerous when wet
      <br/>
      Pyrotechnic signal devices Class 1 - Explosives
      <br/>
      Radium Class 7 - Radioactive materials
      <br/>
      Rocket motors (models) Class 1 - Explosives
      <br/>
      Rust removers or preventers Class 8 - Corrosives
      <br/>
      Sodium sulphide Class 4 - Spontaneous combustion
      <br/>
      Sodium, metallic Class 4 - Dangerous when wet
      <br/>
      Solvents, flammable Class 3 - Flammable liquids
      <br/>
      Sparklers Class 1 - Explosives
      <br/>
      Tear gas devices Class 6 - Toxic substances
      <br/>
      Thermometers, mercury filled Class 8 - Corrosives
      <br/>
      Thinners Class 3 - Flammable liquids
      <br/>
      Toiletries, aerosol Class 2 - Gases
      <br/>
      Varnish and varnish removers, flammable Class 3 - Flammable liquids
      <br/>
      Zinc powder Class 4 - Spontaneous combustion
      <br/>
      Zirconium powder Class 4 - Flammable solids
      </p>


      <h2>Privacy Statement</h2> 
      <p> 
      <b>KS Merchant Pty Ltd</b> strive to provide customers with an efficient, professional and  ethical level of service. We not only comply with the Australian Privacy  Principles, as required by the amendments to the Privacy Act, but also have our  own existing obligations to confidentiality of information.  
      </p>
      <p>
      This statement is provided to explain how we manage the personal information,  that is, information about you or which may identify you that we collect, use,  hold and disclose in the operation of our business.  
      </p>
      <h2>Our overall objective</h2>  
      <p>
      <b>KS Merchant Pty Ltd</b> strive to provide customers with an efficient, professional and  ethical level of service. We not only comply with the Australian Privacy  Principles, as required by the amendments to the Privacy Act, but also have our  own existing obligations to confidentiality of information.  
      </p>
      <p>
      This statement is provided to explain how we manage the personal information,  that is, information about you or which may identify you that we collect, use,  hold and disclose in the operation of our business.  
      </p>
      <p>
      What personal information does <b>KS Merchant Pty Ltd</b> collect? Generally, we collect  the following types of personal information:  
      </p>
      <p>
      • personal names, addresses, contact numbers, credit card information and  any other information provided during the estimate and operational  processes; 
      <br/>
      • information required to execute your delivery instructions;  • information obtained in a franchisee application, including financial details;  
      <br/>
      • information about employees of companies with whom we conduct  business; including our sub-contractors; and your business dealings with us.  
      </p>
      <p>
      Where possible, we collect personal information directly from the  individual to whom the personal information relates.  
      </p>

      <h2>Access to personal information  </h2>
      <p>
      You may seek access to the personal information that we hold about you by  contacting us on the details provided below. Access to personal information  will be granted to a correctly identified person on any weekday during  office hours provided that reasonable notice is given. Only hard copies of  any relevant personal information will be provided. This is because some  computer data may include confidential references other than the personal  details requested.  
      </p>
      <p>
      On the rare occasions when we refuse access, we will provide you with a  written notice stating our reasons for refusing access. Access to personal  information will be provided on the basis that a reasonable fee is paid for  retrieval of the information. Where such payment is not made access will  be denied until the payment is made. 
      </p>
    </div>    
    
    <div className="App">
      <footer className='App-footer'>
        <p>© 2022, KS Merchant Pty. Ltd. All rights reserved. </p>
      </footer>
      </div>
    </div>
  );
}