import '../App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          We are working on it.
        </p>
      </header>
      <footer>
        <p>© 2022, KS Merchant Pty. Ltd. All rights reserved. </p>
      </footer>
    </div>
  );
}

export default Home;