import '../App.css';

function Privacy() {
  return (
    <div>
    <div className='Body'>
    <body>
      <div>
      <h1 id="privacy policy-1">Privacy policy</h1>
      <p>KS Merchant manages the information that we collect from you in accordance with applicable privacy legislation. </p>
        {/*}
        <p>By accessing our mobile application you accept and agree to the <a href="/terms/">Terms and Conditions</a> of KS Merchant's user agreement ("User Agreement"), and acknowledge that your personal data may be collected, used and disclosed in accordance with this Privacy Policy. Except for any terms that are defined in this Privacy Policy then all other defined terms shall have the same meaning as that defined in the User Agreement. Note that under KS Merchant's Terms and Conditions, you must not use the Services if you are under 18 years old.</p>
  {*/}
        <p>KS Merchant may, from time to time, modify this Privacy Policy (and update the web page on which it is displayed). If we materially change the ways in which KS Merchant uses or shares your personal data previously collected from you through the Services, KS Merchant will notify you through your KS Merchant account, your registered email address or other communication. You should check that account regularly.</p>
        
        <h3>1. Collection of Your Personal Data</h3>
        <h4>Information we collect directly from you</h4>
        <p>KS Merchant collects personal data when you pre-register with KS Merchant. This includes your full name, email address and phone number. In circumstances where the below information is not provided to us, we may be unable to provide the Services to you and carry out our contractual obligations with you.</p>
        <p>KS Merchant collects personal data when you register with KS Merchant. This includes:</p>
        <ul>
            <li>your name;</li>
            <li>your email address;</li>
            <li>your phone number;</li>
        </ul>
        <p>To enable us to improve our existing services, to create new service features, and to serve you and others with targeted marketing communications, KS Merchant collects information about the way you use the Services, including the transactions you enter into on the Services, your feedback rating (including any references requested using our 'Reference' feature), the bids you make, the comments you post, and the transactions you enter into with our valued affiliate service providers.</p>
        <h4>What other information users can see about you</h4>
        <p>your user ID and all the material that you post is visible and searchable to us only.</p>
        <p>KS Merchant does not in any way control, and does not accept any responsibility or liability</p>
        
        <h3>2. How We Use Your Personal Data</h3>
        <p>KS Merchant may use the information we collect for the following purposes:</p>
        <h5>Identification and authentication</h5>
        <p>Legal ground(s) for use: We need to perform this function in order to allow you to access the Services.</p>
        <h5>To protect KS Merchant and the users of the Services</h5>
        <p>Legal ground(s) for use: It is in our (and users) legitimate interests to ensure that the Services are secure.</p>
        <h5>To customise the content and any advertising displayed on the Services and permit content on the Services (such as postings, marketing or third party advertisements) to be targeted, on an aggregate basis, to the users for whom it is most likely to be relevant</h5>
        <h5>To improve the Services and develop new service features</h5>
        <p>Legal ground(s) for use: We need some of your personal data in order to provide the services to you; it is in our legitimate interests to provide you the best possible services.</p>
        <h5>To provide, maintain and protect the Services and to verify the identity of authorised users of the Services</h5>
        <p>Legal ground(s) for use: We need to perform this function in order to provide a safe and secure environment for our users and we have legitimate interests in protecting the integrity of the Services we offer.</p>
        <h5>Providing your information to a user with whom you have or had a contract facilitated by KS Merchant</h5>
        <p>Legal ground(s) for use: We need to use your personal data in this way to provide the Services you request.</p>
        <h5>As required by law, order of a court, tribunal or regulator or if KS Merchant reasonably believes that the use or disclosure of the information is reasonably necessary for enforcement related activities</h5>
        <p>Legal ground(s) for use: On such occasions, we are required by law to process your personal data.</p>
        <h5>To ensure that KS Merchant receives payment of the fees due to it</h5>
        <p>Legal ground(s) for use: We need to use your personal data in this way to fulfil a contract between you and us.</p>
        <h5>To contact you to inform you about promotions or upcoming changes or improvements to the Services</h5>
        <p>Legal ground(s) for use: We only contact you for marketing purposes with your consent; we may contact you regarding changes in the Services because it is in our legitimate interests to keep you informed about service changes that may affect you. See section below for further detail about marketing.</p>
        <h5>To contact you to administer our User Agreement</h5>
        <p>Legal ground(s) for use: For example, we may notify you of a breach, or action a request for a take down notice in response to a claim of copyright infringement.<em></em></p>
        <h5>To conduct research</h5>
        <p>Legal ground(s) for use: It is in our legitimate interests to improve the Services through user questionnaires and feedback requests via the Services.</p>
        <h5>To expand our user base, including marketing communications to be targeted to potential users</h5>
        <p>Legal ground(s) for use: It is in our legitimate interest to inform potential users about the Services we offer.</p>
        <h5>To develop our relationships with affiliate service providers and provide or arrange internal or external verification services obtained by you via the Services</h5>
        <p>Legal ground(s) for use: It is in our legitimate interests to engage service providers and verification services.</p>
        <h5>To generate data reports on an aggregated, non-personally identifiable basis, for both internal and third party use, but subject to any applicable laws (for example, we may show advertisers or investors trends relating to the general use of the Services); and</h5>
        <p>Legal ground(s) for use: It is in our legitimate interests (and the interests of our partners and affiliates) to understand how you and other users engage with the Services.</p>
        <h5>Your contact information may also be used for accounting, invoicing and billing purposes, marketing purposes, by third party service providers to KS Merchant, and to respond to any enquiry you make</h5>
        <p>Legal ground(s) for use: It is in our legitimate interests to engage service providers to assist us in delivering the Services you request.</p>
        <h5>When you contact KS Merchant, we may keep a record of the communication(s) between you and KS Merchant to help resolve any issues you might have</h5>
        <p>Legal ground(s) for use: We retain information when we are required to do so by law and because it is in our legitimate interests to protect our legal rights.</p>
        <h5>If other user(s) of the Services already have your userID (or other information identifying you), and you have chosen to upload a photo or other personal data to your KS Merchant account, we may show those user(s) that personal data</h5>
        <p>Legal ground(s) for use: We display your photo to other users who have your userID only with your consent, which you supply by uploading your photo.</p>
        <p>We (or our service providers on our behalf) may use your name, street address, email address, phone number, and data collected from cookies and similar technologies about how you use the Services (including tasks created and certain actions taken on the site) to conduct data analytics, including the creation of look-alike audiences. We use the results of data analytics for marketing, product and service development, and policy development.</p>
        <p>We do not use sensitive information for data analytics.</p>
        
        
        <h3>3. Cookies and Similar Technologies</h3>
        <p>KS Merchant uses cookies and similar tracking technologies for a number of purposes including to access your information when you sign in, keep track of your preferences, direct specific content to you, report on KS Merchant's user base, and to improve KS Merchant's services. </p>
        
        
        <h3>4. Security</h3>
        <p>Your account is protected by a password for your privacy and security. We take reasonable steps to protect your personal data from unauthorized access, use and disclosure, however we cannot guarantee the absolute security of that information, or that our systems will be completely free from third party interception or are incorruptible from viruses. We cannot and do not guarantee that information you send from your computer to us over the Internet will be protected by any form of encryption (encoding software). In light of this, we cannot and do not ensure or warrant the security or privacy of your personal data, including payment and account details. You transmit your personal data to us at your own risk. You are entirely responsible for maintaining the security of your passwords and/or account information.</p>
        
        <h3>5. Children’s Privacy</h3>
        <p>We do not knowingly collect, maintain, or use personal data from children under 18 years of age, and no part of the Services are directed to children. If you learn that a child has provided us with personal data in violation of this Privacy Policy, please alert us using our contact information below.</p>
        
        <h3>6. Contact us</h3>
        <p>If you have any questions about this Privacy Policy or about the manner in which we process your personal data, please contact us:</p><p>ksmerchant-admin@ksmerchant.com.au</p>
        
        <h3>Australian Privacy Terms</h3>
        <p>If You are a User who has Your KS Merchant Platform account in (or the Services are performed in) Australia then the following terms will also apply to or may vary this Privacy Policy, to the extent specified:</p><p>a. a reference to 'personal data' shall also mean 'personal information' as defined in the Privacy Act 1988 (Cth);</p><p>b. a reference to 'sensitive information' has the same meaning given to it in the Privacy Act 1988 (Cth); and</p><p>c. if we collect, use or disclose any information from you in accordance with this Privacy Policy that is also sensitive information (such as information relating to police checks conducted on users), then we will seek consent directly from you each time we collect, use or disclose your sensitive information, including for direct marketing purposes. We will not use or disclose your sensitive information for any other purpose unless the purpose is directly related to the primary purpose for which your sensitive information was collected.</p>        
        </div>
    </body>
    </div>
    
    <div className="App">
      <footer className='App-footer'>
        <p>© 2022, KS Merchant Pty. Ltd. All rights reserved. </p>
      </footer>
      </div>
    </div>
  );
}

export default Privacy;